import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { BUY_TO_LET_FLOW, BUY_TO_LET_FLOW_STEPS } from '@app/sign-up/constants/buy-to-let-flow';
import { FIRST_TIME_BUYER_FLOW, FIRST_TIME_BUYER_FLOW_STEPS } from '@app/sign-up/constants/first-time-buyer-flow';
import { LANDLORD_FLOW, LANDLORD_FLOW_STEPS } from '@app/sign-up/constants/landlord-flow';
import { MORTGAGE_MONITOR_FLOW, MORTGAGE_MONITOR_FLOW_STEPS } from '@app/sign-up/constants/mortgage-monitor-flow';
import { MOVING_HOME_FLOW, MOVING_HOME_FLOW_STEPS } from '@app/sign-up/constants/moving-home-flow';
import { FlowSteps } from '@shared/store/sign-up/flow/flow.state';

export const FLOWS: Partial<Record<HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod, { flow: Record<string, number>; steps: FlowSteps }>> =
  {
    [HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MORTGAGE_MONITOR]: {
      flow: MORTGAGE_MONITOR_FLOW,
      steps: MORTGAGE_MONITOR_FLOW_STEPS,
    },
    [HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_FIRST_TIME_BUYER]: {
      flow: FIRST_TIME_BUYER_FLOW,
      steps: FIRST_TIME_BUYER_FLOW_STEPS,
    },
    [HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MOVING_HOME]: {
      flow: MOVING_HOME_FLOW,
      steps: MOVING_HOME_FLOW_STEPS,
    },
    [HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_BUY_TO_LET]: {
      flow: BUY_TO_LET_FLOW,
      steps: BUY_TO_LET_FLOW_STEPS,
    },
    [HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_LANDLORD]: {
      flow: LANDLORD_FLOW,
      steps: LANDLORD_FLOW_STEPS,
    },
  };
